import React from 'react'
import SwaggerUI from "swagger-ui";
import ApiDocumentation from "../components/ApiDocumentation";

const ApiDocumentationPlugin = api_key => {
  return {
    components: {
      ApiDocumentation: ApiDocumentation
    },
    rootInjects: {
      api_key: api_key
    }
  }
}

const DisableTryItOutPlugin = api_key => {
  if (api_key === "---LOGIN_OR_REGISTER_FOR_FREE_TO_INSERT_TRANSIT_API_KEY_HERE---" || api_key === "---LOGIN_OR_REGISTER_FOR_FREE_TO_INSERT_TICKETING_API_KEY_HERE---") {
    return {
      statePlugins: {
        spec: {
          wrapSelectors: {
            allowTryItOutFor: () => () => false
          }
        }
      }
    }
  } else {
    return null
  }
}
document.addEventListener('turbolinks:load', () => {
  let destination = document.querySelector("#api_documentation_element");
  if (destination) {
    let key_type = destination.dataset['type']
    let api_key = "---LOGIN_OR_REGISTER_FOR_FREE_TO_INSERT_"+key_type.toUpperCase()+"_API_KEY_HERE---"
    let api_section = destination.dataset['apiSection'];
    fetch(window.heimdall_server_url + 'api_keys.json', { method: 'GET', credentials: 'include' })
      .then(response => response.json())
      .then(all_keys => all_keys.find(key => key["is_default_"+key_type.toLowerCase()+"_test_key"]).api_key)
      .then(default_api_key => api_key = default_api_key)
      .finally(() => {
        window.ui = SwaggerUi(api_section, document.getElementById('api-version-select').value, api_key)
        document.getElementById('api-version-select').addEventListener('change', (event) => {
          if ('URLSearchParams' in window) {
            var searchParams = new URLSearchParams(window.location.search);
            searchParams.set("api_version", event.target.value);
            window.location.search = searchParams.toString();
          }
          window.ui = SwaggerUi(api_section, event.target.value, api_key)
        })
      })
  }
})

const SwaggerUi = (api_section, api_version, api_key) => {
  return SwaggerUI({
    url: "/" + api_section + "/swagger/v" + api_version + "/swagger.yaml",
    dom_id: "#api_documentation_element",
    deepLinking: true,
    showExtensions: true,
    showCommonExtensions: true,
    presets: [
      SwaggerUI.presets.apis
    ],
    plugins: [
      SwaggerUI.plugins.DownloadUrl,
      SwaggerUI.plugins.Topbar,
      ApiDocumentationPlugin(api_key),
      DisableTryItOutPlugin(api_key)
    ],
    layout: "ApiDocumentation",
    onComplete: function() {
      ui.preauthorizeApiKey("API-Key via HTTP-Header", api_key);
    },
  })
}
