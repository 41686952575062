import React from "react"

class ApiDocumentation extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { getComponent } = this.props

    const BaseLayout = getComponent("BaseLayout", true)
    const is_signed_in = (this.props.api_key !== "---LOGIN_OR_REGISTER_FOR_FREE_TO_INSERT_TRANSIT_API_KEY_HERE---" && this.props.api_key !== "---LOGIN_OR_REGISTER_FOR_FREE_TO_INSERT_TICKETING_API_KEY_HERE---")
    const sign_in = !is_signed_in ?
      <p>In order to try out the APIs directly in this page, you need to <a href="/signin">Sign In</a> first</p>
      : ""
    return (
        <div>
          {sign_in}
          <BaseLayout/>
        </div>
    )
  }
}

export default ApiDocumentation
